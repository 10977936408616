@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .spin-button-none::-webkit-outer-spin-button,
  .spin-button-none::-webkit-inner-spin-button {
    appearance: none;
  }

  .animate-gelatine {
    animation: gelatine 0.5s;
  }
  @keyframes gelatine {
    from,
    to {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }

  .animate-scale {
    animation: scale 0.2s;
    transform-origin: 0% 0%;
  }
  .animate-scale-reverse {
    animation: scale-reverse 0.2s;
    transform-origin: 0% 0%;
  }
  @keyframes scale {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @keyframes scale-reverse {
    0% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  }

  .custom-scroll-bar::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  /* Track */
  .custom-scroll-bar::-webkit-scrollbar-track {
    background: #222222;
  }

  /* Handle */
  .custom-scroll-bar::-webkit-scrollbar-thumb {
    background: rgb(224, 201, 251);
  }

  /* Handle on hover */
  .custom-scroll-bar::-webkit-scrollbar-thumb:hover {
    background: rgb(143, 190, 245);
  }

  /* change color for date input */
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  /* slides */
  .slide-in-from-right {
    animation: slideInFromRight 0.5s ease-in;
  }
  .slide-out-to-left {
    animation: slideOutToLeft 0.5s ease-in;
  }
  .slide-in-from-left {
    animation: slideInFromLeft 0.5s ease-in;
  }
  .slide-out-to-right {
    animation: slideOutToRight 0.5s ease-in;
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes slideOutToLeft {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes slideOutToRight {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(100%);
    }
  }
}
